<template>
  <div>
    <v-card v-if="!GET_POIS_LOADED">
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </v-card>
    <v-card v-if="GET_POIS_LOADED && doesIdExist">
      <v-card-title>{{ getData() | getPoiMapName }}</v-card-title>

      <v-card-text>
        <div v-if="getLocName()" class="my-4 text-subtitle-1">
          {{ getLocName() }}
        </div>
        <div class="my-4 text-subtitle-1">
          {{ getAmenity() | localizeAmenities }}
        </div>
        <div v-if="getNote()">
          {{ getNote() }}
        </div>
      </v-card-text>

      <v-card-text v-if="getOutdoorSeating()">
        Sitzplätze im Freien: {{ getOutdoorSeating() }}
      </v-card-text>

      <v-card-text>
        <poi-opening-hours :poi="getData()" />
      </v-card-text>
      <v-card-text>
        <poi-contact-and-info :poi="getData()" />
      </v-card-text>
      <v-divider :v-if="showMap" class="mx-4"></v-divider>

      <poi-map
        :v-if="showMap"
        :pois="[getData()]"
        style="height: 30vh"
        :center="getLocationCoordinates()"
        :zoom="15"
      >
        <template v-slot:default="slotProps">
          <poi-title :poi="slotProps.poi" />
        </template>
      </poi-map>

      <v-card-actions>
        <poi-share-button :poi="getData()" />
        <poi-bookmark-button :poi="getData()" />
        <poi-visit-button :poiId="id" />
      </v-card-actions>
    </v-card>
    <v-card v-if="GET_POIS_LOADED && !doesIdExist">
      <v-card-title>Id {{ id }} nicht gefunden.</v-card-title>
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import PoiBookmarkButton from "../components/poi/PoiBookmarkButton.vue";
import PoiContactAndInfo from "../components/poi/PoiContactAndInfo.vue";
import PoiOpeningHours from "../components/poi/PoiOpeningHours.vue";
import PoiShareButton from "../components/poi/PoiShareButton.vue";
import PoiVisitButton from "../components/poi/PoiVisitButton.vue";
import PoiTitle from "../components/poi/PoiTitle.vue";
import PoiMap from "../components/PoiMap.vue";
import { GET_POI, GET_POIS_LOADED } from "../store/modules/poistore";
import { latLng } from "leaflet";
const { mapGetters } = createNamespacedHelpers("pois");

export default {
  components: {
    PoiContactAndInfo,
    PoiOpeningHours,
    PoiVisitButton,
    PoiShareButton,
    PoiBookmarkButton,
    PoiMap,
    PoiTitle,
  },
  name: "PoiDetail",
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      showMap: true,
    };
  },
  computed: {
    ...mapGetters([GET_POI, GET_POIS_LOADED]),
    doesIdExist() {
      return this.GET_POI(this.id) !== undefined;
    },
    poiAlreadyVisited: {
      get() {
        return this.$store.state.obj.message;
      },
      set(value) {
        this.$store.commit("updateMessage", value);
      },
    },
  },
  methods: {
    getData() {
      const poiData = this.GET_POI(this.id);
      return poiData;
    },
    getLocName() {
      return this.getData().loc_name;
    },
    getOutdoorSeating() {
      return this.getData().outdoor_seating;
    },
    getAmenity() {
      return this.getData().amenity;
    },
    getNote() {
      return this.getData().note;
    },
    getLocationCoordinates() {
      const poi = this.getData();
      return latLng(poi.lat, poi.lon);
    },
  },
};
</script>
<style></style>
